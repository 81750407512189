import React from 'react';
import MAKLogo from "../../../../assets/images/MAKLogoSystem.png"
import '../style.scss'
import { Image } from 'react-bootstrap';

const Footer = ({ scrollTo3dTour }) => {
    return (
      <div className='footer'>
          <div className="footer-wrapper">
              <div className="footer-menu">
                  <div>
                      <Image src={MAKLogo}  />
                  </div>
                  <div className="list-one">
                      <h4 className="list-title">Menu</h4>
                      <ul className="list">
                          <li className="margin20">
                              <a href="#about"
                                 className="list-item">
                                  About Property
                              </a>
                          </li>
                          <li className="margin20">
                              <a href="#section"
                                 className="list-item">
                                  Gallery
                              </a>
                          </li>
                          <li className="margin20">
                              <a href="#extras"
                                 className="list-item">
                                  Free extras
                              </a>
                          </li>
                          <li className="margin20">
                              <a href="#map" className="list-item">
                                  Nearby Attractions
                              </a>
                          </li>
                          <li>
                              <a href="#exclusives" className="list-item">
                                  Concierge Extras
                              </a>
                          </li>
                      </ul>
                  </div>
                  <div className="list-two">
                      <h4 className="list-title">Social</h4>
                      <ul className="list">
                          <li className="margin20">
                              <a href={'https://www.instagram.com/makproperties/'}
                                 target="_blank"
                                 className="list-item">
                                  Instagram
                              </a>
                          </li>
                          <li className="margin20">
                              <a href={'https://www.airbnb.com/users/show/45832356'}
                                 target="_blank"
                                 className="list-item">
                                  AirBnB
                              </a>
                          </li>
                          <li>
                              <a href="https://www.facebook.com/makrealtygroup" target="_blank"
                                 className="list-item">
                                  Facebook
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="footer-button-container">
                  <p className="button-label">Have a question?</p>
                  <button className="button"
                          onClick={() => window.open('https://vacations.makrealty.com/contact/', '_blank')}>Contact us
                  </button>
              </div>
          </div>
          <div className='footer-bottom'>
              <button className='footer-bottom-button' onClick={scrollTo3dTour}>RESERVE YOUR STAY</button>
          </div>
          <div className='footer-copyrigh-container'>
              <div className="footer-copyright">
                  <div className='footer-copyright-buttons'>
                      <a className='footer-copyright-buttons-text'
                         href="https://vacations.makrealty.com/privacy-policy/" target='_blank'>
                          Privacy policy
                      </a>
                      <a className='footer-copyright-buttons-text'
                         href="https://vacations.makrealty.com/terms-and-conditions/" target='_blank'>
                          Terms of service
                      </a>
                  </div>
                  <div className='footer-copyright-text'>
                      © 2024 MakRealtyGroup.
                  </div>
              </div>
          </div>
      </div>
    )
}

export default Footer