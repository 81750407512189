import React, { useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-hot-toast';
import { Button, Spinner } from 'react-bootstrap';
import Api from '../../../Api';
import PaymentInfo from './PaymentInfo';
import { ReactComponent as GreenShield } from '../../../assets/icons/greenShield.svg';
import { ReactComponent as Check } from '../../../assets/icons/checkIconCheckotPage.svg';
import paypal from '../../../assets/images/paypal.png';
import stripeImg from '../../../assets/images/stripe.png';
import visa from '../../../assets/images/visa.png';
import norton from '../../../assets/images/norton.png';
import mcafee from '../../../assets/images/mcafee.png';
import master from '../../../assets/images/master.png';
import discover from '../../../assets/images/discover.png';
import amex from '../../../assets/images/amex.png';
import './CheckoutPaymentStripe.scss'

export default function CheckoutPaymentStripeElement(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!props.validateForm()) {
      toast.error('Please fill out all required fields.', {
        style: {
          marginTop: '50px'
        },
      });
      return false;
    }

    if (!props.termsAccepted) {
      toast.error('Please accept terms and conditions', {
        style: {
          marginTop: '50px'
        },
      });
      return;
    }

    setIsLoading(true);
    let loadingToast = toast.loading('Processing...', {
      style: {
        marginTop: '50px'
      },
    });

    const values = props.getBillingValues();
    const arrival = values.arrival;
    const referred_from = values.referred_from;
    const special_requests = values.specialRequests;

    //  Update payment intent with values.

    Api.post('/api/send_feedback', {
      question: 'navigation_difficulty',
      answer: values.feedbackChoice,
      comment: values.feedbackComment,
    });

    Api.post('/api/stripe/update_intent/' + props.paymentIntentId, {
      arrival: arrival,
      referred_from: referred_from,
      special_requests: special_requests,
      vallet_parking: values.valetParking === 'true',
    }).then(async function () {
      //  Now try to charge the payment method.

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion
          // page
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              name: values.first_name + ' ' + values.last_name,
              email: values.email,
              address: {
                line1: values.street1,
                line2: values.street2 ? values.street2 : '',
                city: values.city,
                state: values.state,
                postal_code: values.postal_code,
                country: 'US',
              },
              phone: values.phone,
            },
          },
        },
        redirect: 'if_required',
      });

      setIsLoading(false);

      if (result.error) {
        // Show error to your customer (for example, insufficient funds)
        console.log(result.error.message);
        toast.error(result.error.message, { 
          id: loadingToast,
          style: {
            marginTop: '50px'
          },
        });
        setMessage(result.error.message);
        props.setPaymentSucceeded(false);
        props.setPaymentedAttepemted(true);
      } else {
        // The payment has been processed!
        if (
          result.paymentIntent.status === 'succeeded' ||
          result.paymentIntent.status === 'processing' ||
          result.paymentIntent.status === 'requires_capture'
        ) {
          // could be pending if paid with bank account
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          props.setPaymentSucceeded(true);
          props.setPaymentedAttepemted(true);
          toast.success('Success! Your booking is confirmed. Check your email for confirmation.', { 
            id: loadingToast,
            style: {
              marginTop: '50px'
            },
          });

          //  Hide payment section.

          document.getElementById('checkout_page_payment_section').style.display = 'none';
          document.getElementById('checkout_page_register_block').style.display = 'none';
          document.getElementById('checkout_page_stripe_submit_button').style.display = 'none';
          document.getElementById('checkout_page_billing_details_section').style.display = 'none';
          document.getElementById('coupon-area').style.display = 'none';

          document.getElementById('page-title').innerHTML = "You're Booked!<br /><small>Check your email for a confirmation.</small>";

          document.getElementById('checkout_page_thank_you_table').style.display = 'block'; //
          document.getElementById('checkout_page_success_section').style.display = 'block';

          window.scrollTo(0, 0);

          //  Fire Facebook Pixel event for conversion.

          window.fbq('track', 'Purchase', {
            value: result.paymentIntent.amount / 100,
            currency: 'USD',
          });

          //  GTM: Send conversion event.

          window.dataLayer.push({
            event: 'conversion',
            transactionId: result.paymentIntent.id,
            transactionTotal: result.paymentIntent.amount / 100,
            transactionProducts: [
              {
                sku: props.listingId,
                price: props.subtotal,
                quantity: 1,
              },
            ],
          });
        }
      }
    });
  };

  const paymentElementOptions = {
    layout: 'accordion',
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
        phone: 'never',
        address: 'never',
      },
    },
  };

  const imagesArray = [
    {
      src: paypal,
      alt: 'paypal'
    },
    {
      src: stripeImg,
      alt: 'stripe'
    },
    {
      src: mcafee,
      alt: 'McAfee'
    },
    {
      src: norton,
      alt: 'norton'
    },
    {
      src: visa,
      alt: 'VISA'
    },
    {
      src: master,
      alt: 'mastercard'
    },
    {
      src: amex,
      alt: 'American Express'
    },
    {
      src: discover,
      alt: 'Discover'
    }
  ];

  return (
    <>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {message && <div id="payment-message">{message}</div>}
      <PaymentInfo setTermsAccepted={props.setTermsAccepted} termsAccepted={props.termsAccepted} />
      <Button
        onClick={handleSubmit}
        disabled={isLoading || !props.termsAccepted}
        className={'w-100 mt-4'}
        id={'checkout_page_stripe_submit_button'}
      >
        {isLoading ? <Spinner /> : 'BOOK NOW'}
      </Button>
      <div className='guaranteed-safe-container'>
        <p className='guaranteed-text'>
          Guaranteed Safe Checkout
        </p>
        <div className='guaranteed-safe-icons'>
          <div className='green-icon-container'>
            <div className='green-icon-overlay'>
              <GreenShield className='shield' />
              <Check className='check' />
            </div>
            <p className='green-text'>AES-256bit</p>
          </div>
          {
            imagesArray.map(({src, alt}, index) => (
              <div className='icon-container' key={index}>
                <img src={src} alt={alt} />
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
}
