import React, { Component } from 'react';
import { Accordion, Alert, Button, Col, Form, Row } from 'react-bootstrap';
import Api from '../../Api';
import moment from 'moment';
import { isObject } from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AutoComplete from 'react-google-autocomplete';
import './NewCheckout.scss';
import { Loader } from '../../components/Loader/Loader';
import { toast } from 'react-hot-toast';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBathtub, faBed, faLocationDot, faMoneyBill, faRulerCombined, faStar, faUsers } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Multiselect from '../../components/Multiselect/Multiselect';
import CheckoutPaymentPayPalElement from '../../components/Forms/Checkout/CheckoutPaymentPayPalElement';
import StarRating from '../../components/Rating/NewStarRating';

import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import CheckoutPaymentStripe from '../../components/Forms/Checkout/CheckoutPaymentStripe';
import SuccessfulCheckout from '../../components/Forms/Checkout/SuccessfulCheckout';
import FailedCheckout from '../../components/Forms/Checkout/FailedCheckout';
import { DatePickerAndGuest } from '../../components/Forms/Datepicker/DatepickerAndGuest';

import { extractSquareFeet, formatPrice, updateDatePickerView } from '../../utils';

export class Checkout extends Component {
  constructor(props) {
    super(props);

    const queryParameters = new URLSearchParams(window.location.search);
    const user = this.props.user;

    this.state = {
      paymentError: null,
      listingData: [],
      formData: {
        first_name: user ? user.first_name : null,
        last_name: user ? user.last_name : null,
        email: user ? user.email : null,
        phone: user ? user.phone : null,
      },
      confNumber: null,
      validated: false,
      formRef: React.createRef(),
      checkIn: queryParameters.get('checkIn'),
      checkOut: queryParameters.get('checkOut'),
      nights: moment(queryParameters.get('checkOut')).diff(queryParameters.get('checkIn'), 'days'),
      adults: parseInt(queryParameters.get('adults')),
      children: parseInt(queryParameters.get('children')) || 0,
      couponCode: '',
      couponExists: false,
      couponExpired: false,
      couponChecked: false,
      couponMessage: '',
      couponDiscount: 0,
      paymentIntentId: '',
      totalDiscount: 0,
      feedbackChoice: '',
      valetParking: null,
      feedbackComment: '',
      specialRequests: [],
      specialRequestsRestaurant: '',
      specialRequestsClub: '',
      specialRequestsOther: '',
      paymentSystem: 'stripe',
      termsAccepted: false,
      isAccordionOpen: false,
      paymentedAttepemted: false,
      paymentSucceeded: false,
      reservations: [],
      bookDisabled: false,
      isVertical: false,
    };

    this.fetchListing = this.fetchListing.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getBillingValues = this.getBillingValues.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleCouponChange = this.handleCouponChange.bind(this);
    this.checkCoupon = this.checkCoupon.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.setPaymentIntentId = this.setPaymentIntentId.bind(this);
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
    this.handleParkingChange = this.handleParkingChange.bind(this);
    this.handleFeedbackCommentChange = this.handleFeedbackCommentChange.bind(this);
    this.setTermsAccepted = this.setTermsAccepted.bind(this);
    this.setPaymentError = this.setPaymentError.bind(this);
    this.cuponAlreadyAccepted = this.cuponAlreadyAccepted.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.setPaymentSucceeded = this.setPaymentSucceeded.bind(this);
    this.setPaymentedAttepemted = this.setPaymentedAttepemted.bind(this);
  }

  setPaymentedAttepemted(attepted) {
    this.setState({ paymentedAttepemted: attepted });
  }

  setPaymentSucceeded(succeeded) {
    this.setState({ paymentSucceeded: succeeded });
  }

  toggleAccordion() {
    this.setState((prevState) => ({
      isAccordionOpen: !prevState.isAccordionOpen,
    }));
  }

  setTermsAccepted(accepted) {
    this.setState({ termsAccepted: accepted });
  }

  setPaymentIntentId(intentId) {
    this.setState({
      paymentIntentId: intentId,
    });

    if (this.state.couponCode) {
      this.checkCoupon();
    }
  }

  handleSpecialRequestsChange = (selected) => {
    this.setState({
      specialRequests: selected,
    });
  };

  handleCouponChange(event) {
    if (this.state.listingData.pricing.monthly_discount || this.state.listingData.pricing.weekly_discount) {
      this.cuponAlreadyAccepted();
      this.setState({
        couponCode: '',
        couponChecked: false,
      });
    } else {
      this.setState({
        couponCode: event.target.value,
        couponChecked: false,
      });
    }
  }

  checkCoupon(event) {
    Api.get('/api/stripe/coupon/' + this.state.couponCode).then((response) => {
      if (response.data.error) {
        window.localStorage.removeItem('promo');

        this.setState({
          couponExists: false,
          couponExpired: false,
          couponChecked: true,
          couponMessage: response.data.error,
        });
      } else {
        this.setState(
          {
            couponExists: true,
            couponExpired: response.data.valid,
            couponChecked: true,
          },
          function () {
            Api.post('/api/stripe/coupon/' + this.state.couponCode + '/' + this.state.paymentIntentId).then((response) => {
              if (response.data.discountAmount) {
                this.setState({
                  couponMessage: 'Coupon applied',
                  couponDiscount: response.data.discountAmount,
                  totalDiscount: parseFloat(response.data.discountAmount) + parseFloat(this.state.listingData.pricing.discount),
                });
              }
            });
          },
        );
      }
    });
  }

  removeCoupon() {
    window.localStorage.removeItem('promo');

    Api.delete('/api/stripe/coupon/' + this.state.paymentIntentId).then((response) => {
      this.setState({
        couponCode: '',
        couponExists: false,
        couponExpired: false,
        couponChecked: false,
        couponMessage: '',
        couponDiscount: 0,
      });
    });
  }

  cuponAlreadyAccepted() {
    toast.error('You can not have multiple coupons at once.', {
      style: {
        backgroundColor: '#333',
        color: '#ddd',
      },
      duration: 2 * 1000,
    });
  }

  fetchListing(inputs) {
    let axiosUrl = `/api/listings/${this.props.listingId}?include=pricing,amenities`;
    let { checkIn, checkOut, adults, children } = this.state;
  
    if (inputs) {
      this.setState({ loading: true });
      inputs.forEach((input) => {
        const { name, value } = input;
        if (name === 'from_date') checkIn = value;
        else if (name === 'to_date') checkOut = value;
        else if (name === 'adults') adults = value;
        else if (name === 'children') children = value;
      });
  
      window.history.replaceState(null, null, `?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`);
    }
  
    axiosUrl += `&checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`;
  
    Api.get(axiosUrl)
      .then((response) => {
        this.setState({
          listingData: response.data,
          checkIn,
          checkOut,
          adults,
          children,
          reservations: response.data.reservations || [],
          loading: false,
        });

        if (response.data.reservations_count >= 8) {
          const randomTimeout = Math.random() * 5000 + 5000;
          setTimeout(() => {
            toast.success(`This property was booked ${response.data.reservations_count} times in the last month!`, {
              position: 'bottom-left',
              duration: 4000,
              style: { backgroundColor: '#333', color: '#ddd' },
              icon: '🔥',
            });
          }, randomTimeout);
        }


        //  Now that we have the listing data, fire the Facebook pixel

  
        //  Now that we have the listing data, fire the Facebook pixel

        window.fbq('track', 'InitiateCheckout');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: { step: 1 },
              products: [
                {
                  name: response.data.name,
                  id: response.data.id,
                  price: response.data.pricing.total,
                  brand: 'Mak Realty Group',
                  category: 'Real Estate',
                  quantity: 1,
                },
              ],
            },
          },
        });
      })
      .catch((error) => console.error(error));
  }  

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newData = this.state.formData;
    newData[name] = value && value.trim().length ? value : '';
    this.setState({
      formData: newData,
    });
  }

  handleFeedbackChange(event) {
    this.setState({
      feedbackChoice: event.target.value,
    });
  }

  handleParkingChange(event) {
    this.setState({
      valetParking: event.target.value,
    });
  }

  handleFeedbackCommentChange(event) {
    this.setState({
      feedbackComment: event.target.value,
    });
  }

  setPaymentError(error) {
    this.setState({
      paymentError: error,
    });
  }

  getBillingValues() {
    const selected = this.state.specialRequests;

    let specialRequestsText = selected.join(', ');
    if (selected.includes('Reservation at restaurant')) {
      specialRequestsText += '; Restaurant: ' + this.state.specialRequestsRestaurant;
    }
    if (selected.includes('Reservation at club')) {
      specialRequestsText += '; Club: ' + this.state.specialRequestsClub;
    }
    if (selected.includes('Other')) {
      specialRequestsText += '; Other: ' + this.state.specialRequestsOther;
    }

    return {
      ...this.state.formData,
      feedbackChoice: this.state.feedbackChoice,
      valetParking: this.state.valetParking,
      feedbackComment: this.state.feedbackComment,
      specialRequests: specialRequestsText,
      total: this.state.listingData.pricing.total,
      discount: this.state.couponDiscount / 100,
      couponCode: this.state.couponCode,
    };
  }

  validateForm() {
    if (!isValidPhoneNumber(`${this.state.formData.phone}`)) return;
    this.setState({
      validated: true,
    });

    return this.state.formRef.current.checkValidity();
  }

  advanceNoticePassed = () => {
    const checkInDate = new Date(this.state.checkIn);
    checkInDate.setHours(checkInDate.getHours() - this.state.listingData.advance_notice_hours);
    return checkInDate < new Date();
  };

  componentDidMount() {
    const { reservations, bookDisabled } = this.props.location?.state || {};
    this.setState({
      reservations: reservations || [],
      bookDisabled: bookDisabled || false,
    });
    const outerPromoCode = window.localStorage.getItem('promo');
    if (outerPromoCode) {
      this.setState({
        couponCode: outerPromoCode,
      });
    }

    this.fetchListing();
    const { location } = window;
    const params = new URLSearchParams(location.search);
    if (!params.has('children') || params.get('children') === 'null') {
      params.set('children', '0');
      const updatedSearch = params.toString();
      const updatedUrl = `${location.pathname}?${updatedSearch}`;
      window.history.replaceState(null, '', updatedUrl);
    }
    window.scrollTo(0, 0);

    window.fbq('track', 'PageView');

    updateDatePickerView(this.setState.bind(this), 768);
    window.addEventListener('resize', () => updateDatePickerView(this.setState.bind(this)), 768);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => updateDatePickerView(this.setState.bind(this)), 768);
  }

  render() {
    const { user } = this.props;

    const cardImage = this.state.listingData.files && this.state.listingData.files.length ? this.state.listingData.files[0].url : '';
    const squareFeet = extractSquareFeet(this.state.listingData.square_feet, this.state.listingData.description);
    
    return (
      <>
        {this.state.paymentedAttepemted ? (
          <>
            ({' '}
            {this.state.paymentSucceeded ? (
              <>
                ({' '}
                <SuccessfulCheckout
                  data={{
                    firstName: this.state.formData.first_name,
                    lastName: this.state.formData.last_name,
                    phone: this.state.formData.phone,
                    email: this.state.formData.email,
                    totalPrice: this.state.listingData.pricing.subtotal - this.state.totalDiscount,
                    location: this.state.listingData.address
                      ? this.state.listingData.address.street1 + ', ' + this.state.listingData.address.city
                      : 'N/A',
                  }}
                />
              </>
            ) : (
              <FailedCheckout />
            )}
          </>
        ) : (
          <div className={'checkout'}>
            <Row>
              <Col className="heading-bc">
                <div className="breadcrumbs">
                  <Breadcrumbs
                    children={[
                      {
                        text: 'Search Listing',
                        url: '/',
                      },
                      {
                        text: 'Fontainebleau Resort',
                        url: '/',
                      },
                      {
                        text: 'Payment',
                        url: '#',
                      },
                    ]}
                  />
                </div>
                <p className={'payemntError page-title'} id={'page-title'}>
                  {(this.state.paymentError || this.advanceNoticePassed()) &&
                    'Were sorry, this listing is not available for those dates Please choose another listing or different dates. If you need help, please feel free to contact us for support'}
                </p>
              </Col>
            </Row>

            {this.state.listingData.id ? (
              <Row className={`checkout_page_row_container ${!(this.state.paymentError || this.advanceNoticePassed()) && 'margin_listing_data'}`}>
                <Row className="resort-showcase resort-showcase-desktop">
                  <Col md={12} lg={6} className="hotel_info_container">
                    <div className={'text-left'}>
                      <div className={'text-blue mb-2'}>
                        <div className={'d-none d-sm-inline'}>
                          <StarRating
                            rating={isObject(this.state.listingData.rating) ? this.state.listingData.rating.avg : 5}
                            starSize={'20px'}
                            className="star-rating"
                          />
                        </div>
                        <h1 className="resort">
                          {this.state.listingData.tagline ? this.state.listingData.tagline : 'Fontainebleau Resort'}
                        </h1>
                      </div>

                      <Row className="mt-3">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={'pe-0 mb-3 mb-md-0 d-flex align-items-center fs-5 location-title'}
                        >
                          <FontAwesomeIcon className={'fs-3'} icon={faLocationDot} style={{ height: '18px' }} />
                          <div className={'ms-3'}>
                            <h5 className={'text-blue mb-0 location-text'}>
                              {this.state.listingData.address
                                ? this.state.listingData.address.city + ', ' + this.state.listingData.address.state
                                : 'N/A'}
                              <small className={'fw-normal m-3 p-0'}>{this.state.listingData.room_type}</small>
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div id={'checkout_page_listing_details_section'}>
                      <div className="nd_booking_section nd_booking_padding_0_all_iphone nd_booking_box_sizing_border_box">
                        <h6 className="text-blue">
                          {this.state.listingData.name}{' '}
                          {user && (user.email === 'eddy@beetechy.com' || user.email === 'mk@makrealty.com') ? (
                            <small>{this.state.listingData.nickname}</small>
                          ) : (
                            <></>
                          )}
                        </h6>

                        <Row className={'mb-4 fs-5 fw-light '}>
                          <div className="details-showcase">
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon icon={faUsers} className={'me-2 icon-style'} />
                              <p className={' m-0 icon-text'}> {this.state.listingData.number_of_guests} guests</p>
                            </div>
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon icon={faRulerCombined} className={'me-2 icon-style'} />
                              <p className={' m-0 icon-text'}>
                                {squareFeet ? `${squareFeet.toLocaleString()} SF` : 'N/A SF'}
                              </p>
                            </div>
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon icon={faMoneyBill} className={'me-2 icon-style'} />
                              <p className={'m-0 icon-text'}>
                                {this.state.listingData.pricing
                                  ? formatPrice(Number(this.state.listingData.pricing.average?.replace(',', '')))
                                  : '0.00'}{' '}
                                nt.
                              </p>
                            </div>
                          </div>
                          <div className="mt-3 details-showcase">
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon className={'me-2 icon-style'} icon={faBed} />
                              <p className={' m-0 icon-text'}>
                                {this.state.listingData.bedrooms} room
                                {this.state.listingData.bedrooms > 1 || this.state.listingData.bedrooms === '0' ? 's' : null}
                              </p>
                            </div>
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon className={' me-2 icon-style'} icon={faBathtub} />
                              <p className={'m-0 icon-text'}>
                                {this.state.listingData.bathrooms} bath
                                {this.state.listingData.bathrooms > 1 || this.state.listingData.bathrooms === '0' ? 's' : null}
                              </p>
                            </div>
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon icon={faStar} className={'me-2 icon-style'} />
                              <p className={'m-0 icon-text'}>
                                {' '}
                                {this.state.listingData.rating ? this.state.listingData.rating.avg : 5}(
                                {this.state.listingData.rating ? this.state.listingData.rating.total : 25})
                              </p>
                            </div>
                          </div>
                        </Row>
                      </div>

                      <div className="mb-5"></div>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="image-wrapper">
                      <img src={cardImage} alt="listing-picture" style={{ width: '100%' }} />
                    </div>
                  </Col>
                </Row>
                <Row className="dates-container align-items-start">
                  <Col md={12} lg={6} className="col-section">
                    <DatePickerAndGuest
                      search={this.fetchListing}
                      bookDisabled={this.state.bookDisabled}
                      reservation={this.state.reservations}
                      isVertical={this.state.isVertical}
                    />
                  </Col>
                  <Col md={12} lg={6} className="col-section">
                    <div className="checkout_page_register_block" id="checkout_page_register_block">
                      <p className="registerText">
                        {this.props.user ? <>Welcome back, {this.props.user.first_name}!</> : 'Register to manage your booking'}
                      </p>
                      <div className={'d-flex align-items-center'}>
                        {this.props.user ? (
                          <Link className={'btn'} to="/logout">
                            Log out
                          </Link>
                        ) : (
                          <Link className={'btn'} to="/register">
                            Register
                          </Link>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col xs={12} sm={12} md={12} lg={6} className="guest_details_container guest_details_container_with_no_padding">
                    <div className="form-wrapper" id={'checkout_page_billing_details_section'}>
                      <h2 className={'mb-4 text-blue'}>Guest Details</h2>
                      <Form id="checkout-form" noValidate validated={this.state.validated} ref={this.state.formRef}>
                        <Row className={'mb-3 form-double-row'}>
                          <Form.Group>
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                              placeholder={'First name'}
                              required
                              name={'first_name'}
                              type="text"
                              minLength={2}
                              pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              value={this.state.formData.first_name}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                              placeholder={'Last name'}
                              required
                              name={'last_name'}
                              type="text"
                              minLength={2}
                              pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              value={this.state.formData.last_name}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your last name.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className={'mb-3 form-double-row'}>
                          <Form.Group controlId="validationCustom01">
                            <Form.Label>Address</Form.Label>
                            <AutoComplete
                              options={{
                                types: ['address'],
                              }}
                              type={'text'}
                              required
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              className={'form-control'}
                              placeholder={'Enter a location'}
                              name={'street1'}
                              value={this.state.formData.street1}
                              onChange={this.handleInputChange}
                              apiKey={'AIzaSyBklzBrDOZsVZDAzrNcSUeycnyrqTTLzFY'}
                              onPlaceSelected={(place) => {
                                var street_number;
                                var route;
                                var city;
                                var state;
                                var country;
                                var postal_code;

                                for (var i = 0; i < place.address_components.length; i++) {
                                  var component = place.address_components[i];
                                  if (component.types.includes('locality')) city = component.long_name;
                                  if (component.types.includes('sublocality')) city = component.long_name;
                                  if (component.types.includes('administrative_area_level_1')) state = component.long_name;
                                  if (component.types.includes('postal_code')) postal_code = component.long_name;
                                  if (component.types.includes('country')) country = component.short_name;
                                  if (component.types.includes('street_number')) street_number = component.long_name;
                                  if (component.types.includes('route')) route = component.long_name;
                                }

                                var newData = this.state.formData;
                                newData['street1'] = street_number + ' ' + route;
                                newData['city'] = city;
                                newData['state'] = state;
                                newData['postal_code'] = postal_code;
                                newData['country'] = country;

                                this.setState({
                                  formData: newData,
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">Please enter your address.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>Apt.</Form.Label>
                            <Form.Control
                              placeholder={'or suite'}
                              name={'street2'}
                              type="text"
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              value={this.state.formData.street2}
                            />
                          </Form.Group>
                        </Row>
                        <Row className={'mb-3'}>
                          <Form.Group sm="5" className={'mb-3 mb-sm-0'}>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              placeholder={'City'}
                              required
                              name={'city'}
                              type="text"
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              value={this.state.formData.city}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your city.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className={'mb-3 form-double-row'}>
                          <Form.Group sm="4" controlId="validationCustom01">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                              placeholder={'State'}
                              required
                              name={'state'}
                              type="text"
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              value={this.state.formData.state}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your state.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group sm="3" controlId="validationCustom01">
                            <Form.Label>ZIP</Form.Label>
                            <Form.Control
                              placeholder={'ZIP'}
                              required
                              name={'postal_code'}
                              type="text"
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              value={this.state.formData.postal_code}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your postal code.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className={'mb-3 form-double-row'}>
                          <Form.Group className={'mb-3 mb-md-0'}>
                            <Form.Label>Phone number</Form.Label>
                            <PhoneInput
                              placeholder={'Phone number'}
                              required
                              name={'phone'}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              type="tel"
                              onChange={(e) =>
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    phone: e,
                                  },
                                })
                              }
                              value={this.state.formData.phone}
                              id="user-phone-input"
                              international
                              defaultCountry="US"
                              className={this.state.formData.phone ? (isValidPhoneNumber(this.state.formData.phone) ? '' : 'invalid') : ''}
                            />
                            <Form.Control.Feedback type="invalid" className="phone-error">
                              {this.state.formData.phone
                                ? isValidPhoneNumber(this.state.formData.phone)
                                  ? undefined
                                  : 'Invalid phone number'
                                : ''}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group controlId="validationCustom01">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              placeholder={'Email address'}
                              required
                              name={'email'}
                              type="email"
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              onChange={this.handleInputChange}
                              value={this.state.formData.email}
                              readOnly={this.props?.user}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className={'mb-3'}>
                          <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>Special requests</Form.Label>

                            <Multiselect
                              options={[
                                'Car Rental',
                                'Yacht Rental',
                                'Reservation at restaurant',
                                'Reservation at club',
                                'Decorating Service',
                                'Other',
                              ]}
                              onChange={this.handleSpecialRequestsChange}
                              placeholder="Do you need a car or yacht rental? How about reservations at a restaurant or club?"
                            />
                          </Form.Group>
                        </Row>

                        {this.state.specialRequests.includes('Reservation at restaurant') && (
                          <Row className={'mb-3'}>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Special requests: Reservation at restaurant</Form.Label>
                              <Form.Control
                                disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                                placeholder={'Restaurant'}
                                required
                                name={'restaurant'}
                                type="text"
                                onChange={(e) => this.setState({ specialRequestsRestaurant: e.target.value })}
                              />
                            </Form.Group>
                          </Row>
                        )}

                        {this.state.specialRequests.includes('Reservation at club') && (
                          <Row className={'mb-3'}>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Special requests: Reservation at club</Form.Label>
                              <Form.Control
                                placeholder={'Club'}
                                disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                                required
                                name={'club'}
                                type="text"
                                onChange={(e) => this.setState({ specialRequestsClub: e.target.value })}
                              />
                            </Form.Group>
                          </Row>
                        )}

                        {this.state.specialRequests.includes('Other') && (
                          <Row className={'mb-3'}>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Special requests: Other</Form.Label>
                              <Form.Control
                                disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                                placeholder={'Other'}
                                required
                                name={'other'}
                                type="text"
                                onChange={(e) => this.setState({ specialRequestsOther: e.target.value })}
                              />
                            </Form.Group>
                          </Row>
                        )}

                        <Row className={'mb-3'}>
                          <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>What time will you arrive?</Form.Label>
                            <Form.Select
                              className={'form-control'}
                              placeholder={' '}
                              onChange={this.handleInputChange}
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              required
                              name="arrival"
                              value={this.state.formData.arrival}
                            >
                              <option value="" disabled selected></option>
                              <option>I do not know</option>
                              <option>12:00 - 1:00 am</option>
                              <option>1:00 - 2:00 am</option>
                              <option>2:00 - 3:00 am</option>
                              <option>3:00 - 4:00 am</option>
                              <option>4:00 - 5:00 am</option>
                              <option>5:00 - 6:00 am</option>
                              <option>6:00 - 7:00 am</option>
                              <option>7:00 - 8:00 am</option>
                              <option>8:00 - 9:00 am</option>
                              <option>9:00 - 10:00 am</option>
                              <option>10:00 - 11:00 am</option>
                              <option>11:00 - 12:00 am</option>
                              <option>12:00 - 1:00 pm</option>
                              <option>1:00 - 2:00 pm</option>
                              <option>2:00 - 3:00 pm</option>
                              <option>3:00 - 4:00 pm</option>
                              <option>4:00 - 5:00 pm</option>
                              <option>5:00 - 6:00 pm</option>
                              <option>6:00 - 7:00 pm</option>
                              <option>7:00 - 8:00 pm</option>
                              <option>8:00 - 9:00 pm</option>
                              <option>9:00 - 10:00 pm</option>
                              <option>10:00 - 11:00 pm</option>
                              <option>11:00 - 12:00 pm</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">Please select a choice.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>Where did you hear about us?</Form.Label>
                            <Form.Select
                              className={'form-control'}
                              onChange={this.handleInputChange}
                              required
                              disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                              name="referred_from"
                            >
                              <option value="" disabled selected></option>
                              <option>Google</option>
                              <option>AirBnB</option>
                              <option>Facebook</option>
                              <option>Yelp</option>
                              <option>Instagram</option>
                              <option>Email From Us</option>
                              <option>A Friend</option>
                              <option>Returning Guest</option>
                              <option>Other</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        {this.state.formData.referred_from && this.state.formData.referred_from.includes('Other') && (
                          <Row className={'mb-3 mt-3'}>
                            <Form.Group as={Col} md="12">
                              <Form.Label>Where did you hear about us: Other</Form.Label>
                              <Form.Control
                                placeholder={'Other'}
                                required
                                disabled={!!(this.state.paymentError || this.advanceNoticePassed())}
                                name={'referred_from_other'}
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      referred_from: 'Other: ' + e.target.value,
                                    },
                                  })
                                }
                              />
                            </Form.Group>
                          </Row>
                        )}
                        <Row>
                          <Form.Group as={Col} md="12" className="mt-3">
                            <Col>
                              <Form.Label>Do you need valet parking?</Form.Label>
                            </Col>
                            <Col style={{ marginLeft: '1em' }}>
                              <Form.Check
                                required
                                type="radio"
                                inline
                                name="valet_parking"
                                label="Yes"
                                value={true}
                                onChange={this.handleParkingChange}
                              />
                              <Form.Check
                                required
                                type="radio"
                                inline
                                name="valet_parking"
                                label="No"
                                value={false}
                                onChange={this.handleParkingChange}
                              />
                            </Col>
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group as={Col} md="12" className="mt-3">
                            <Col>
                              <Form.Label>Was the website difficult to navigate?</Form.Label>
                            </Col>
                            <Col style={{ marginLeft: '1em' }}>
                              <Form.Check
                                required
                                type="radio"
                                inline
                                name="navigation_difficulty"
                                label="Yes"
                                value="yes"
                                onChange={this.handleFeedbackChange}
                              />
                              <Form.Check
                                required
                                type="radio"
                                inline
                                name="navigation_difficulty"
                                label="No"
                                value="no"
                                onChange={this.handleFeedbackChange}
                              />
                            </Col>

                            {this.state.feedbackChoice === 'yes' && (
                              <Col className="mt-3">
                                <Form.Label>What was difficult to navigate?</Form.Label>
                                <Form.Control
                                  placeholder={'Please provide feedback'}
                                  name={'feedback'}
                                  as="textarea"
                                  style={{ height: '100px' }}
                                  onChange={this.handleFeedbackCommentChange}
                                  value={this.state.feedbackComment}
                                  required
                                  minLength={5}
                                />
                                <Form.Control.Feedback type="invalid">Please provide feedback</Form.Control.Feedback>
                                <Form.Control.Feedback type="valid">Thank you for your feedback!</Form.Control.Feedback>
                              </Col>
                            )}
                          </Form.Group>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6} className="col-section">
                    <div className="checkout_page_right_block">
                      <Row id={'checkout_page_success_section'} className={'mb-4'}>
                        <Col md={12}>
                          <Alert variant="primary">
                            {this.state.listingData.manual_approve_booking ? (
                              <>
                                <Alert.Heading>Booking Pending</Alert.Heading>
                                <p>
                                  Hello {this.state.formData.first_name + ' ' + this.state.formData.last_name},
                                  <br />
                                  Thank you for choosing MAK Realty Group to book your beautiful getaway!
                                  <br />
                                  Please understand and be aware that your reservation is NOT confirmed as of yet. We kindly ask that you
                                  allow us up to 6 hours for a member of our firm to verify and confirm your reservation at{' '}
                                  {this.state.listingData.tagline}.
                                  <br />
                                  To avoid any inconvenience, we would like to confirm if the dates you have selected are still available to
                                  accommodate your group.
                                  <br />
                                  Rest assured that before the approval, your credit card is pre-authorized for the total amount. This means
                                  that there is only a HOLD and not an actual charge. Once your reservation is approved by our team,
                                  confirmation will be sent, and your payment will go through.
                                  <br />
                                  Thank you for your patience, and we look forward to connecting soon.
                                  <br />— The MAK Team
                                </p>
                              </>
                            ) : (
                              <>
                                <Alert.Heading>You're Booked!</Alert.Heading>
                                <p>
                                  You have successfully booked your trip. You will receive a confirmation email shortly. Thank you for
                                  choosing MAK Realty.
                                </p>
                              </>
                            )}
                          </Alert>
                        </Col>
                      </Row>
                      <h1 className="text-blue payment-info-header">Payment Information</h1>
                      <Accordion className="breakdownAcardion" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header
                            className="price-accordion-title"
                            onClick={this.toggleAccordion}
                            style={{
                              'font-family': 'Montserrat',
                              'font-size': '14px',
                              'font-weight': '500',
                            }}
                          >
                            {this.state.isAccordionOpen ? 'HIDE' : 'SHOW'} PRICE BREAKDOWN
                          </Accordion.Header>
                          <Accordion.Body style={{ padding: '0' }}>
                            <div className="pricing_table_wrapper">
                              <table>
                                <thead>
                                  <tr>
                                    <td>
                                      {this.state.nights} Night(s), {this.state.adults + this.state.children} Guest(s)
                                    </td>
                                    <td>amount</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.listingData.pricing.breakdown &&
                                    this.state.listingData.pricing.breakdown.map((array, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{moment(array.date).format('DD MMM')}</td>
                                          <td>
                                            ${formatPrice(array.price)}
                                          </td>
                                        </tr>
                                      );
                                    })}

                                  {this.state.listingData.pricing.cleaning_fee ? (
                                    <tr>
                                      <td>Cleaning fee</td>
                                      <td>
                                        ${formatPrice(this.state.listingData.pricing.cleaning_fee)}
                                      </td>
                                    </tr>
                                  ) : (
                                    ''
                                  )}

                                  {this.state.listingData.pricing.extra_guest_fee ? (
                                    <tr>
                                      <td>Extra guests fee</td>
                                      <td>
                                        ${formatPrice(this.state.listingData.pricing.extra_guest_fee)}
                                      </td>
                                    </tr>
                                  ) : (
                                    ''
                                  )}

                                  {this.state.listingData.pricing.guest_security_deposit ? (
                                    <tr>
                                      <td>Guest security deposit</td>
                                      <td>
                                        ${formatPrice(this.state.listingData.pricing.guest_security_deposit)}
                                      </td>
                                    </tr>
                                  ) : (
                                    ''
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="pricing_table_wrapper">
                        <tfoot>
                          <tr>
                            <td className="darker">Subtotal</td>
                            <td className="darker">
                              ${this.state.listingData.pricing.subtotal ? formatPrice(this.state.listingData.pricing.subtotal) : 'N/A'}
                            </td>
                          </tr>
                          {this.state.listingData.pricing.monthly_discount || this.state.listingData.pricing.weekly_discount ? (
                            <tr>
                              <td className="darker">
                                {this.state.listingData.pricing.monthly_discount
                                  ? 'MONTHLY ' + this.state.listingData.pricing.monthly_percent + '% OFF'
                                  : 'WEEKLY ' + this.state.listingData.pricing.weekly_percent + '% OFF'}
                              </td>
                              <td className="darker">
                                -$
                                {Math.abs(parseFloat(this.state.listingData.pricing.discount)).toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )}

                          <tr>
                            <td className="darker">Discount</td>
                            <td className="darker">
                              -$
                              {this.state.couponDiscount
                                ? (parseFloat(this.state.couponDiscount) / 100).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : '0.00'}
                            </td>
                          </tr>
                          <tr>
                            <td className="darker">Taxes</td>
                            <td className="darker">
                              ${this.state.listingData.pricing.tax ? formatPrice(this.state.listingData.pricing.tax) : 'N/A'}
                            </td>
                          </tr>
                          {this.state.listingData.pricing.extra_cleaning_fee ? (
                            <tr>
                              <td className="darker">Cleaning fee</td>
                              <td className="darker">
                                ${formatPrice(this.state.listingData.pricing.extra_cleaning_fee)}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          <tr>
                            <td className="darker">Transaction fee</td>
                            <td className="darker">
                              ${this.state.listingData.pricing.fee ? formatPrice(this.state.listingData.pricing.fee) : 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td className="darker">Total</td>
                            <td className="darker">
                              $
                              {this.state.listingData.pricing.total
                                ? formatPrice(this.state.listingData.pricing.total - this.state.couponDiscount / 100)
                                : 'N/A'}
                            </td>
                          </tr>
                        </tfoot>
                      </div>
                      <Form.Group className="mb-3 mt-3">
                        <Row id="coupon-area">
                          <Col xs={12} md="4">
                            <Form.Label className="mt-2">Coupon Code</Form.Label>
                          </Col>
                          <div className="coupon-input ">
                            <div>
                              <Form.Control
                                type="text"
                                placeholder="Coupon Code"
                                name="coupon_code"
                                onChange={this.handleCouponChange}
                                value={this.state.couponCode}
                              />
                            </div>

                            <div style={{ color: 'transparent' }}>
                              {!this.state.couponDiscount && (
                                <Button
                                  variant="primary"
                                  onClick={
                                    this.state.listingData.pricing.monthly_discount || this.state.listingData.pricing.weekly_discount
                                      ? this.cuponAlreadyAccepted
                                      : this.checkCoupon
                                  }
                                  disabled={
                                    this.state.listingData.pricing.monthly_discount ||
                                    this.state.listingData.pricing.weekly_discount ||
                                    !this.state.couponCode.length ||
                                    this.state.paymentError || 
                                    this.advanceNoticePassed()
                                  }
                                >
                                  APPLY
                                </Button>
                              )}
                              {this.state.couponDiscount && (
                                <Button variant="primary" onClick={this.removeCoupon}>
                                  REMOVE
                                </Button>
                              )}
                            </div>
                          </div>
                          <Col md="12">
                            {this.state.couponMessage.length > 0 && <p className="text-blue mt-2">{this.state.couponMessage}</p>}
                          </Col>
                        </Row>
                      </Form.Group>

                      <div className={'pricing_table_wrapper mt-4'} id={'checkout_page_thank_you_table'}>
                        <h1
                          style={{
                            fontWeight: 700,
                            fontSize: '21px',
                            letterSpacing: '2px',
                            color: 'white',
                          }}
                        >
                          GUEST <span style={{ fontWeight: 400 }}>INFORMATION</span>
                        </h1>

                        <table>
                          <tbody>
                            <tr>
                              <td className={'darker'}>Name</td>
                              <td>
                                {this.state.formData.first_name} {this.state.formData.last_name}
                              </td>
                            </tr>

                            <tr>
                              <td className={'darker'}>Email</td>
                              <td>{this.state.formData.email}</td>
                            </tr>
                            <tr>
                              <td className={'darker'}>Phone</td>
                              <td>{this.state.formData.phone}</td>
                            </tr>

                            <tr>
                              <td className={'darker'}>Address</td>
                              <td>
                                {this.state.formData.street1}
                                {this.state.formData.street2 ? ' ' + this.state.formData.street2 : ''} {this.state.formData.city},{' '}
                                {this.state.formData.state} {this.state.formData.postaL_code}
                              </td>
                            </tr>

                            <tr>
                              <td className={'darker'}>Special Requests</td>
                              <td>{this.getBillingValues()['specialRequests'] || 'None'}</td>
                            </tr>

                            <tr>
                              <td className={'darker'}>Time of Arrival</td>
                              <td>{this.state.formData.arrival}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {!this.state.paymentError ? (
                        <Tabs
                          id={'checkout_page_payment_section'}
                          onSelect={(index) =>
                            this.setState({
                              paymentSystem: index === 0 ? 'stripe' : 'paypal',
                            })
                          }
                        >
                          <div className={'my-4'} />

                          <TabList className={'checkout_page_payment_option-wrapper'}>
                            <Tab className={'checkout_page_payment_option'}>CREDIT / DEBIT</Tab>

                            <Tab className={'checkout_page_payment_option'}>PAYPAL</Tab>
                          </TabList>

                          <TabPanel className={'payment-panel'}>
                            <CheckoutPaymentStripe
                              listingId={this.props.listingId}
                              getBillingValues={this.getBillingValues}
                              validateForm={this.validateForm}
                              provideIntentId={this.setPaymentIntentId}
                              setTermsAccepted={this.setTermsAccepted}
                              termsAccepted={this.state.termsAccepted}
                              subtotal={this.state.listingData.pricing.subtotal - this.state.totalDiscount}
                              setPaymentError={this.setPaymentError}
                              discount={this.state.couponDiscount}
                              setPaymentSucceeded={this.setPaymentSucceeded}
                              setPaymentedAttepemted={this.setPaymentedAttepemted}
                            />
                          </TabPanel>

                          <TabPanel className={'payment-panel'}>
                            <CheckoutPaymentPayPalElement
                              formData={this.state.formData}
                              listingId={this.props.listingId}
                              getBillingValues={this.getBillingValues}
                              total={
                                this.state.listingData.pricing.total
                                  ? this.state.listingData.pricing.total - this.state.couponDiscount / 100
                                  : 0
                              }
                              validateForm={this.validateForm}
                              setTermsAccepted={this.setTermsAccepted}
                              termsAccepted={this.state.termsAccepted}
                              listing={this.state.listingData}
                              setPaymentSucceeded={this.setPaymentSucceeded}
                              setPaymentedAttepemted={this.setPaymentedAttepemted}
                            />
                          </TabPanel>
                        </Tabs>
                      ) : null}
                    </div>
                  </Col>
                  <Row className="resort-showcase resort-showcase-mobile">
                    <Col md={12} lg={6} className="hotel_info_container">
                      <div className={'text-left'}>
                        <div className={'text-blue mb-2'}>
                          <div className={'d-none d-sm-inline'}>
                            <StarRating
                              rating={isObject(this.state.listingData.rating) ? this.state.listingData.rating.avg : 5}
                              starSize={'20px'}
                              className="star-rating"
                            />
                          </div>
                          <h1 className="resort">
                            {this.state.listingData.tagline ? this.state.listingData.tagline : 'Fontainebleau Resort'}
                          </h1>
                        </div>

                        <Row className="mt-3">
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className={'pe-0 mb-3 mb-md-0 d-flex align-items-center fs-5 location-title'}
                          >
                            <FontAwesomeIcon className={'fs-3'} icon={faLocationDot} style={{ height: '18px' }} />
                            <div className={'ms-3'}>
                              <h5 className={'text-blue mb-0 location-text'}>
                                {this.state.listingData.address
                                  ? this.state.listingData.address.city + ', ' + this.state.listingData.address.state
                                  : 'N/A'}
                                <small className={'fw-normal m-3 p-0'}>{this.state.listingData.room_type}</small>
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div id={'checkout_page_listing_details_section'} 
                           className='checkout_page_listing_details_section_mobile'>
                        <div className="">
                          <h6 className="text-blue">
                            {this.state.listingData.name}{' '}
                            {user && (user.email === 'eddy@beetechy.com' || user.email === 'mk@makrealty.com') ? (
                              <small>{this.state.listingData.nickname}</small>
                            ) : (
                              <></>
                            )}
                          </h6>

                          <Row className={'mt-1 mb-4 fs-5 fw-light'}>
                            <div className="details-showcase d-flex flex-wrap">
                              <div className="col-6 details-showcase-col">
                                <div className={'d-flex align-items-center mb-3'}>
                                  <FontAwesomeIcon icon={faUsers} className={'me-2 icon-style'} />
                                  <p className={'m-0 icon-text'}>{this.state.listingData.number_of_guests} guests</p>
                                </div>
                                <div className={'d-flex align-items-center mb-3'}>
                                  <FontAwesomeIcon className={'me-2 icon-style'} icon={faBed} />
                                  <p className={'m-0 icon-text'}>
                                    {this.state.listingData.bedrooms} room
                                    {this.state.listingData.bedrooms > 1 || this.state.listingData.bedrooms === '0' ? 's' : null}
                                  </p>
                                </div>
                                <div className={'d-flex align-items-center'}>
                                  <FontAwesomeIcon icon={faMoneyBill} className={'me-2 icon-style'} />
                                  <p className={'m-0 icon-text'}>
                                    {this.state.listingData.pricing
                                      ? new Intl.NumberFormat('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                          maximumFractionDigits: 0,
                                        }).format(this.state.listingData.pricing.average.replace(',', ''))
                                      : 0}{' '}
                                    nt.
                                  </p>
                                </div>
                              </div>

                              <div className="col-6 details-showcase-col">
                                <div className={'d-flex align-items-center mb-3'}>
                                  <FontAwesomeIcon icon={faRulerCombined} className={'me-2 icon-style'} />
                                  <p className={'m-0 icon-text'}>
                                    {this.state.listingData.square_feet ? this.state.listingData.square_feet.toLocaleString() : 'N/A'} SF
                                  </p>
                                </div>
                                <div className={'d-flex align-items-center mb-3'}>
                                  <FontAwesomeIcon className={'me-2 icon-style'} icon={faBathtub} />
                                  <p className={'m-0 icon-text'}>
                                    {this.state.listingData.bathrooms} bath
                                    {this.state.listingData.bathrooms > 1 || this.state.listingData.bathrooms === '0' ? 's' : null}
                                  </p>
                                </div>
                                <div className={'d-flex align-items-center'}>
                                  <FontAwesomeIcon icon={faStar} className={'me-2 icon-style'} />
                                  <p className={'m-0 icon-text'}>
                                    {' '}
                                    {this.state.listingData.rating ? this.state.listingData.rating.avg : 5}(
                                    {this.state.listingData.rating ? this.state.listingData.rating.total : 25})
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>

                        <div className="mb-5"></div>
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className="image-wrapper">
                        <img src={cardImage} alt="listing-picture" style={{ width: '100%' }} />
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Row>
            ) : (
              <Loader />
            )}
          </div>
        )}
        {this.state.loading && <Loader />}
      </>
    );
  }
}
