export function extractSquareFeet(squareFeet, description) {
    if (squareFeet) {
      return squareFeet;
    }

    if (description) {
      const pattern = /\b(\d{1,4}(?:,\d{3})?)\s*(?:sq\.?\s*ft\.?|square\s*foot\.?|square\s*feet\.?)\b/i;
      
      const matches = description.match(pattern);
      
      if (matches && matches[1]) {
        const extractedValue = parseInt(matches[1].replace(/,/g, ''), 10);
        return isNaN(extractedValue) ? null : extractedValue;
      }
    }

    return null;
}

export const formatPrice = (amount) => {
  if (isNaN(amount) || amount === undefined || amount === null) {
    return '0.00';
  }
  
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const updateDatePickerView = (setState, screen) => {
  if (window.innerWidth <= screen) {
    setState({ isVertical: true });
  } else {
    setState({ isVertical: false });
  }
};

export const isBeforeToday = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};
