import React from "react";
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import PaymentInfo from "./PaymentInfo";
import Api from "../../../Api";
import { toast } from "react-hot-toast";

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export default function CheckoutPaymentPayPalElement({listingId, formData, getBillingValues, total, validateForm, setTermsAccepted, termsAccepted, listing, setPaymentSucceeded, setPaymentedAttepemted}) {
 
    const payPalPublicKey = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PAYPAL_TEST_PUBLIC_KEY : process.env.REACT_APP_PAYPAL_LIVE_PUBLIC_KEY;
    const payPalSDKOptions = {
        "client-id": payPalPublicKey,
        currency: "USD",
        intent: listing.manual_approve_booking ? 'authorize' : 'capture'
    }
   
    return (
        <PayPalScriptProvider options={payPalSDKOptions}>
            <PaymentInfo setTermsAccepted={setTermsAccepted} termsAccepted={termsAccepted} />
            <PayPalButtons
                disabled={!termsAccepted}
                forceReRender={[termsAccepted]}
                fundingSource="paypal"
                className="mt-4"
                style={{ layout: "horizontal", color: "white", label: "checkout", tagline: false, height: 40 }}
                createOrder={(data, actions) => {
                    if (!validateForm()) {
                        toast.error("Please fill out all required fields.", {
                            style: {
                                marginTop: '50px'
                            },
                        });
                        return;
                    }

                    if (!termsAccepted) {
                        toast.error("Please accept terms and conditions", {
                            style: {
                                marginTop: '50px'
                            },
                        });
                        return;
                    }

                    const queryParameters = new URLSearchParams(window.location.search);

                    return actions.order.create({
                        intent: listing.manual_approve_booking ? 'AUTHORIZE' : 'CAPTURE',
                        purchase_units: [
                            {
                                amount: {
                                    value: getBillingValues().total - getBillingValues().discount,
                                },
                                reference_id: listingId,
                            },
                        ],
                        "payment_source": {
                            "paypal": {
                                "experience_context": {
                                "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED",
                                "payment_method_selected": "PAYPAL",
                                "brand_name": "MAKREALTY",
                                "locale": "en-US",
                                "landing_page": "LOGIN",
                                "user_action": "PAY_NOW",
                                }
                            }
                        },
                        application_context: {
                            custom: 'data',
                        }
                    }).then(orderId => {
                        const fields = getBillingValues();
                    
                        Api.post('/api/send_feedback', {
                            question : 'navigation_difficulty',
                            answer : fields.feedbackChoice,
                            comment : fields.feedbackComment,
                        })

                        Api.post('/api/paypal/create-order',
                        {
                            listingId: listingId,
                            checkIn: queryParameters.get("checkIn"),
                            checkOut: queryParameters.get("checkOut"),
                            adults: queryParameters.get("adults"),
                            children: queryParameters.get("children") || 0,
                            referredFrom: fields.referred_from,
                            referredFromUrl: getCookie("referral_url"),
                            arrival: fields.arrival,
                            specialRequests: fields.specialRequests,
                            phone: fields.phone,
                            email: fields.email,
                            first_name: fields.first_name,
                            last_name: fields.last_name,
                            street1: fields.street1,
                            street2: fields.street2,
                            city: fields.city,
                            state: fields.state,
                            postal_code: fields.postal_code,
                            orderId: orderId,
                            couponDiscount: fields.discount,
                            couponCode: fields.couponCode,
                            vallet_parking:fields.valetParking === "true"        
                                      }
                        ).then(res => {
                        })
                        .catch(err => console.log(err))

                        return orderId;
                    })
                    .catch(err => console.log(err))
                }}
                onApprove={(data, actions) => {
                    const action = listing.manual_approve_booking ? actions.order.authorize : actions.order.capture;
                   
                    action().then(res => {
                    
                        if (res.status !== 'COMPLETED') {
                            return;
                        }
                        try {
                            setPaymentSucceeded(true);
                            setPaymentedAttepemted(true);
                    

                            toast.success("Success! Your booking is confirmed. Check your email for confirmation.", {
                                style: {
                                    marginTop: '50px'
                                },
                            })
                            window.scrollTo(0, 0);
                            document.getElementById('checkout_page_payment_section').style.display         = "none";
                            document.getElementById('checkout_page_register_block').style.display          = "none";
                            document.getElementById('checkout_page_billing_details_section').style.display = "none";
                            document.getElementById('coupon-area').style.display                           = "none";

                            document.getElementById('page-title').innerHTML                                = "You're Booked!<br /><small>Check your email for a confirmation.</small>";

                            document.getElementById('checkout_page_thank_you_table').style.display         = "block"; //
                            document.getElementById('checkout_page_success_section').style.display         = "block";

                            //   window.gtag('event', 'conversion', {
                            //     'send_to':        'AW-11145233893/aaJ2CPjAl5kYEOWLu8Ip',
                            //     'value':          total,
                            //     'currency':       'USD',
                            //     'transaction_id': data.id,
                            // }); 

                        } catch (e) {
                            toast.error("Something Went Wrong!", {
                                style: {
                                    marginTop: '50px'
                                },
                            })
                         }
                    })
                    .catch(err => console.log(err))

                }}
                onError={(err) => console.log(err)}

            />
        </PayPalScriptProvider>
    )
}