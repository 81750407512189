import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast'; 
import { format } from 'date-fns';
import { ReactComponent as PlanIcon } from '../../../../assets/icons/grid-ruler.svg';
import { ReactComponent as RoomsIcon } from '../../../../assets/icons/Group.svg';
import { ReactComponent as BathsIcon } from '../../../../assets/icons/Group1.svg';
import Api from '../../../../Api';
import { formatPrice } from '../../../../utils';
import { DatePickerAndGuest } from '../../../../components/Forms/Datepicker/DatepickerAndGuest';
import { Loader } from '../../../../components/Loader/Loader';

const StartTourItem = ({ item }) => {

  const [startTour, setStartTour] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookDisabled, setBookDisabled] = useState(false);
  const [isVertical, setIsVertical] = useState(false)

  const queryParams = new URLSearchParams(window.location.search);
  let checkIn = queryParams.get('checkIn');
  let checkOut = queryParams.get('checkOut');
  let adults = queryParams.get('adults');
  let children = queryParams.get('children');

  const checkReservationConflict = () => {
    checkIn = queryParams.get('checkIn');
    checkOut = queryParams.get('checkOut');

    let selectedCheckIn = new Date(checkIn);
    let selectedCheckOut = new Date(checkOut);

    const conflict = startTour.reservations.some((reservation) => {
      let reservationCheckIn = new Date(reservation.check_in_date);
      let reservationCheckOut = new Date(reservation.check_out_date);

      return selectedCheckIn < reservationCheckOut && selectedCheckOut > reservationCheckIn && reservation.status === 'confirmed';
    });

    setBookDisabled(conflict);
    conflict && toast.error('Selected days are already reserved');
  }

  const fetchListing = (inputs) => {
    let axiosUrl;
    axiosUrl = 'api/listings/' + item.listingId + '?include=pricing,amenities';
    setLoading(true)
    for (let i = 0; i < inputs.length; i++) {
      let value = inputs[i].value;
      if (inputs[i].name === 'from_date') {
        checkIn = value;
        axiosUrl += '&checkIn=' + checkIn;
      } else if (inputs[i].name === 'to_date') {
        checkOut = value;
        axiosUrl += '&checkOut=' + checkOut;
      }
    }
    window.history.replaceState(
      null,
      null,
      '?checkIn=' + checkIn + '&checkOut=' + checkOut + '&adults=' + adults + '&children=' + children,
    );
    Api.get(axiosUrl)
      .then((res) => {
        setLoading(false);
        setStartTour(res.data)
      })
      .catch((e) => console.log(e));
    checkReservationConflict()
  }

  useEffect(() => {
    if (item.listingId) {
      const url = `${process.env.REACT_APP_API_URL}/api/listings/${item.listingId}?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}&include=pricing,amenities`;
      Api.get(url)
        .then((data) => setStartTour(data.data))
        .catch((error) => setError(error));
    }
  }, [item.listingId, checkIn, checkOut, adults, children]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsVertical(true);
      } else {
        setIsVertical(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleReserve = () => {
    localStorage.setItem("sort", "desc");
    window.open(`/checkout/${item.listingId}?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`, '_blank');
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate + 'T00:00:00');
    const formattedDate = format(date, 'MMM d').toUpperCase();
    return formattedDate;
  };

  return (
    !error && (
      <div className="fb-startTour-items-item">
        <div className='ph-page-date-picker-wrapper'>
          <DatePickerAndGuest
            search={fetchListing}
            reservation={startTour?.reservations} 
            bookDisable={bookDisabled} 
            showTravelersField={false} 
            showCheckInOutTexts={true}
            monthsCount={2}
            isVertical={isVertical}
          />
        </div>
        <div className="fb-startTour-items-item-header">
          <div className="fb-startTour-items-item-header-title">
            {' '}
            FONTAINEBLEAU TRESOR <br></br>{' '}
            <span>
              <b>{item.side}</b> PENTHOUSE
            </span>{' '}
            <br></br> <span>IN MIAMI BEACH</span>
          </div>
          <div className="fb-startTour-items-item-header-priceDiv">
            <div className="fb-startTour-items-item-header-priceDiv-price">
              <div className="fb-startTour-items-item-header-priceDiv-price-text1">
                {`$${formatPrice(startTour?.pricing?.subtotal)}`} <span>*</span>
              </div>
              <div className="fb-startTour-items-item-header-priceDiv-price-text2">taxes not incl.</div>
              <div className="fb-startTour-items-item-header-priceDiv-price-text2 mt-3">
                * Average price per night. To change the dates, proceed to checkout.
              </div>
            </div>
            <div className="fb-startTour-items-item-header-priceDiv-flexCol mt-3">
              <button 
                className={`fb-startTour-items-item-header-priceDiv-flexCol-button ${bookDisabled ? 'reserveButtonDisabled' : ''}`} 
                onClick={handleReserve}
                disabled={bookDisabled}
                >
                  RESERVE
              </button>
              <div className="fb-startTour-items-item-header-priceDiv-price-text3">
                {!bookDisabled ? `${formatDate(checkIn)} - ${formatDate(checkOut,)}` : 'NO DATE SELECTED'}
              </div>
            </div>
          </div>
        </div>
        <div className="fb-startTour-items-item-body">
          <iframe title={`Tour for listing ${item.id}`} className="fb-startTour-items-item-body-image" src={item.iframeSrc} />
          <div className="fb-startTour-items-item-body-drag">
            DRAG <br />& MOVE
          </div>
        </div>
        <div className="fb-startTour-items-item-footer">
          <div className="fb-startTour-items-item-footer-item">
            <PlanIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className="fb-startTour-items-item-footer-item-text">{`${startTour?.square_feet || item.sqft} SqFT`}</div>
          </div>
          <div className="fb-startTour-items-item-footer-item">
            <PlanIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className=" fb-startTour-items-item-footer-item-text">{`${startTour?.square_feet || item.sqm} SqM`}</div>
          </div>
          <div className="fb-startTour-items-item-footer-item">
            <RoomsIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className=" fb-startTour-items-item-footer-item-text">{`${item.side === 'SOUTH' ? item.rooms + ' BR + Office Room' : startTour?.bedrooms + ' BR'}`}</div>
          </div>
          <div className="fb-startTour-items-item-footer-item">
            <BathsIcon className="fb-startTour-items-item-footer-item-icon" />
            <div className=" fb-startTour-items-item-footer-item-text">{`${startTour?.bathrooms} BA`}</div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    )
  );
};

export default StartTourItem;
